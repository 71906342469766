import React from 'react';

// Own components
import SEO from '../components/seo';
import Layout from '../components/Layout/es';
import { Contact } from '../containers/Contact';

const ContactPage = ({ location }) => (
  <Layout>
    <SEO title="Contáctenos" />
    <Contact location={location} />
  </Layout>
);

export default ContactPage;
