import React, { Fragment, useState, useCallback, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';

// Own components
import Icon from '../../components/Icon/icon';
import { SocialNetworks } from '../../components/SocialNetworks';
import SectionCover from '../../components/SectionCover/sectionCover';

// Assets & Misc
import klebLock from '../../images/logos/LogoKlebLockOriginal.png';

export const Contact = () => {
  const { formatMessage } = useIntl();
  const [errorSubmit, setErrorSubmit] = useState('');
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const reCaptchaRef = useRef(null);

  const validateReCaptcha = async token => {
    setReCaptchaToken(token);
  }

  const handleSendMessage = useCallback(async () => {
    setErrorSubmit('');

    let inputs = document.querySelectorAll('input[value=""]');
    const comment = document.querySelector('textarea');
    if (inputs.length > 0 || comment.value === '') {
      setErrorSubmit('contactErrorSubmit');
      return;
    }

    if (!reCaptchaToken) {
      setErrorSubmit('contactErrorSubmit');
      return;
    }

    const formData = new FormData();
    inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      formData.append(input.name, input.value);
    })
    formData.append('token', reCaptchaToken);
    formData.append('custom_U3545', comment.value);

    fetch('https://protecnoing.com/scripts/contact.php', { method: 'POST', body: formData});

    // Reset fiield
    if (reCaptchaRef.current) {
      reCaptchaRef.current.reset();
    }
    comment.value = '';
    inputs.forEach(input => {
      input.value = '';
    })


  }, [reCaptchaToken]);

  return (
    <>
      <SectionCover title={formatMessage({ id: 'contactUs' }, { prefix: '' })} src="/images/contact/contact-cover.jpeg" />
      <section className="pad100-top-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-info-column text-center">
              <Icon icon="whatsapp" className="contact-icon" />
              <h4>WhatsApp</h4>
              <p className="fnt-17">
                <a style={{ color: 'inherit' }} href="https://wa.me/message/2DRO7QKT4JM6B1" rel="noopener noreferrer" target="_blank">+57 313 784 9119</a>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12  contact-info-column text-center">
              <Icon icon="phone" className="contact-icon" />
              <FormattedMessage id="phone" tagName="h4" />
              <p className="fnt-17">
                <a style={{ color: 'inherit' }} href="tel:5723089889" rel="noopener noreferrer" target="_blank">+57 (2) 308 9889</a>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12  contact-info-column text-center">
              <Icon icon="envelope-o" className="contact-icon" />
              <FormattedMessage id="email" tagName="h4" />
              <p className="fnt-17">
                <a style={{ color: 'inherit' }} href="mailto:info@protecnoing.com" rel="noopener noreferrer" target="_blank">info@protecnoing.com</a>
              </p>
            </div>
          </div>
          <div className="row text-center">
            <img data-src="/images/protecno.png" alt="Protecno Ing" style={{ maxHeight: 60, maxWidth: '100%' }} className="lazyload" />
            <form id="contactForm" autoComplete="off" noValidate="novalidate">
              <div className="contact-form">
                <div className="col-lg-12 col-md-12 form-field">
                  <input name="custom_U3549" id="name" type="text" className="form-input" placeholder={`${formatMessage({ id: 'name' })}*`} required />
                </div>
                <div className="col-lg-12 col-md-12 form-field">
                    <input name="custom_U13122" id="email" type="text" className="form-input" placeholder={`${formatMessage({ id: 'email' })}*`} required />
                </div>
                <div className="col-md-6 form-field input-halfrght">
                  <input name="custom_U3526" id="company"type="text" className="form-input" placeholder={`${formatMessage({ id: 'company' })}*`} required />
                </div>
                <div className="col-md-6 form-field input-halflft">
                    <input name="custom_U3449" id="phone" type="text" className="form-input" placeholder={`${formatMessage({ id: 'phone' })}*`} required />
                </div>
                <div className="col-md-6 form-field input-halfrght">
                  <input name="custom_U3530" id="occupation"type="text" className="form-input" placeholder={`${formatMessage({ id: 'occupation' })}*`} required />
                </div>
                <div className="col-md-6 form-field input-halflft">
                    <input name="custom_U3445" id="city" type="text" className="form-input" placeholder={`${formatMessage({ id: 'city' })}*`} required />
                </div>
                <div className="col-lg-12 col-md-12 form-field">
                    <textarea name="custom_U3545" id="question" cols="1" rows="3" className="form-comment" placeholder={`${formatMessage({ id: 'question' })}*`} />
                </div>
                <div className="col-lg-12 col-md-12 form-field">
                    <input name="custom_U3541" id="howDidYouKnowAboutUs" type="text" className="form-input" placeholder={`${formatMessage({ id: 'howDidYouKnowAboutUs' })}*`} required />
                </div>
                <div className="col-lg-12 col-md-12 form-field center-recaptcha">
                  <ReCAPTCHA ref={reCaptchaRef} sitekey="6LdQf84UAAAAAFiwqmWSwH9GmcIcMSwr9AHDxZ52" onChange={validateReCaptcha}/>
                </div>
                {errorSubmit && <p className="error-message"><FormattedMessage id={errorSubmit} /></p>}
                <div className="col-md-12 form-field no-margin">
                  <button onClick={handleSendMessage} className="form-submit-btn btn" type="button"><FormattedMessage id="send" /></button>
                </div>
              </div>
			      </form>
          </div>
        </div>
      </section>
      <SocialNetworks />
    </>
  );
};
